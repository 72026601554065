import * as locales from "date-fns/locale";
import { registerLocale } from "react-datepicker";

Object.entries(locales)
  ?.filter(([key, _]) => key.length === 2)
  ?.forEach(([key, locale]) => {
    registerLocale(key, locale);
  });

export const SUPPORTED_LOCALES = Object.keys(locales)?.filter(
  locale => locale.length === 2
);
